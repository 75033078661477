<template>
  <div>
    <cui-error-500 />
  </div>
</template>
<script>
import CuiError500 from '@/components/cleanui/system/Errors/500'
export default {
  components: {
    CuiError500,
  },
}
</script>
